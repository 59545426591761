
import {computed} from 'vue'
import ElMemberChip from 'src/components/member/el-member-chip.vue'

export default {
	name: 'el-committee',
	components: {ElMemberChip},
	props: {
		committee: {type: Object, required: true},
	},
	setup(p) {
		const notedMembers = computed(() => (p.committee as any).current_members?.filter((m) => !!m.note))
		const otherMembers = computed(() => (p.committee as any).current_members?.filter((m) => !m.note))

		return {
			notedMembers,
			otherMembers,
			party: (code) => code === 'R' ? 'Republican' : code === 'D' ? 'Democrat' : 'Independent'
		}
	},
}
