<template>
	<cam-box class="el-committee">
		<h2 class="m0">{{committee.name}}</h2>
		<h5 class="m0">{{committee.congress}}th Congress</h5>
		<br>
		<i class="lni lni-link"/>&nbsp;
		<a :href="committee.url" target="_blank"><span>Offical Site</span></a>
		<br><br>
		<hr>

		<h3>Members</h3>
		<cam-box class="member-list">
			<el-member-chip thumbnail v-for="m in notedMembers" :key="m.id" :id="m.id">
				<cam-box flex="flex-start" dir="column">
					<h4 class="m0">{{m.name}} &nbsp;<strong v-if="m.note">| {{m.note}}</strong></h4>
					<h5>{{party(m.party)}} from {{m.state}}</h5>
				</cam-box>
			</el-member-chip>
		</cam-box>
		<hr><br><br>
		<cam-box class="member-list">
			<el-member-chip thumbnail v-for="m in otherMembers" :key="m.id" :id="m.id">
				<cam-box flex="flex-start" dir="column">
					<h4 class="m0">{{m.name}} &nbsp;<strong v-if="m.note">| {{m.note}}</strong></h4>
					<h5>{{party(m.party)}} from {{m.state}}</h5>
				</cam-box>
			</el-member-chip>
		</cam-box>
	</cam-box>
</template>

<script lang="ts">
import {computed} from 'vue'
import ElMemberChip from 'src/components/member/el-member-chip.vue'

export default {
	name: 'el-committee',
	components: {ElMemberChip},
	props: {
		committee: {type: Object, required: true},
	},
	setup(p) {
		const notedMembers = computed(() => (p.committee as any).current_members?.filter((m) => !!m.note))
		const otherMembers = computed(() => (p.committee as any).current_members?.filter((m) => !m.note))

		return {
			notedMembers,
			otherMembers,
			party: (code) => code === 'R' ? 'Republican' : code === 'D' ? 'Democrat' : 'Independent'
		}
	},
}
</script>

<style lang="stylus" scoped>
.el-committee {
	max-width: 1024px
}
.member-list {
	display: grid
	grid-template-columns: 1fr 1fr
	grid-column-gap: 2em
	flex-wrap: wrap
}
</style>