<template>
	<div class="el-committee-viewer box">
		<el-committee v-if="committee" :committee="committee"/>
	</div>
</template>

<script lang="ts">
import {CURRENT_CONGRESS} from 'domain/Constants'
import {computedAsync} from 'src/util/computed'
import useEllisContext from 'src/useEllisContext'
import ElCommittee from './el-committee.vue'

export default {
	name: 'el-committee-viewer',
	components: {ElCommittee},
	props: {
		chamber: {type: String, required: true},
		code: {type: String, required: true},
	},
	setup(p) {
		const {api, store, profile} = useEllisContext()
		const fetchCommittee = () => api.committee.get(CURRENT_CONGRESS, p.chamber, p.code)

		const committee = computedAsync(() => fetchCommittee())

		return {
			profile,
			committee,
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.el-committee-viewer {
	flexXY(flex-start, center)
	flex-direction: column
}
</style>